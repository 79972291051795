import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import Logo from "../images/logo.svg"
import LogoIcon from "../images/logo_icon.svg"
import Thumbnail from "../images/tinyman.png"
import { Link } from "gatsby"
import Marquee from "react-fast-marquee";
import BrandPopup from "../components/brandPopup"

export default function Header(props) {

  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSticky(window.pageYOffset > 400)
      );
    }
  }, []);

  return (
  <>
    <Helmet>
      <title>Tinuman | Memecoin on Algorand</title>
      <meta charset="utf-8"></meta>
      <meta name="description" content="Tinuman | Memecoin on Algorand." />
      <meta property="og:image" content={Thumbnail} />
    </Helmet>

    <div class="sliding-text">
      <Marquee gradient={false} speed={20}>
      <ul>
        <li>Tinuman Memecoin</li>
        <li>Algorand Ecosystem</li>
        <li>Tinuman Memecoin</li>
        <li>Algorand Ecosystem</li>
        <li>Tinuman Memecoin</li>
        <li>Algorand Ecosystem</li>
        <li>Tinuman Memecoin</li>
        <li>Algorand Ecosystem</li>
        <li>Tinuman Memecoin</li>
        <li>Algorand Ecosystem</li>
        <li>Tinuman Memecoin</li>
        <li>Algorand Ecosystem</li>
        <li>Tinuman Memecoin</li>
        <li>Algorand Ecosystem</li>
      </ul>
      </Marquee>
    </div>
  <div class="static-header">
    <header>
    <Link className="logo" to="/" onContextMenu={togglePopup} id="logo">
        <img src={Logo} alt="Tinyman"/>
      </Link>
      <ul class="menu">
        <li><Link to="/airdrop">Airdrop</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main">
            Community
          </button>
          <ul class="dropdown">
            <li><a href="https://twitter.com/tinualgo" target="_blank" rel="noreferrer">Twitter</a></li>
            <li><a href="https://t.me/tinualgo" target="_blank" rel="noreferrer">Telegram</a></li>
          </ul>
        </li>
        <li class="gotoapp">
          <a href="https://app.tinyman.org/swap?asset_in=0&asset_out=2217970766" class="mainnet_btn" target="_blank" rel="noreferrer">Go to Tinyman v2</a></li>
      </ul>
    </header>
  </div>
  <div className={`fixed-header ${
          sticky ? "sticky" : ""
        }`}>
    <header>
      <Link to="/" onContextMenu={togglePopup}>
        <img src={LogoIcon} alt="Tinyman" />
      </Link>
      <ul class="menu">
        <li><Link to="/airdrop">Airdrop</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main">
            Community
          </button>
            <ul class="dropdown">
            <li><a href="https://twitter.com/tinualgo" target="_blank" rel="noreferrer">Twitter</a></li>
            <li><a href="https://t.me/tinualgo" target="_blank" rel="noreferrer">Telegram</a></li>
            </ul>
        </li>
        <li>
          <a href="https://app.tinyman.org/swap?asset_in=0&asset_out=2217970766" class="mainnet_btn" target="_blank" rel="noreferrer"><span>Go to Tinyman v2</span></a></li>
      </ul>
    </header>
  </div>
  {isOpen && <BrandPopup handleClose={togglePopup} />}

  </>
  )
}
