import * as React from "react"

export default function brandPopup(props) {
  return (
  <div class="brand_popup">
    <button class="overlay" onClick={props.handleClose}>
    </button>
  </div>
  )
}
