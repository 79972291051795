import * as React from "react"
import { Link } from "gatsby"

export default function Header(props) {
  return (
  <div class="container">
      <div class="section-links">
        <section>
          <div class="link_heading">
            <h3>Resources</h3>
            <p>Join the Tinuman revolution and be a part of the memecoin movement that’s redefining engagement and entertainment in the crypto world. Follow us on social media, participate in community events, and let’s make Tinuman the next big thing on Algorand!</p>
           </div>
          <div>
              <div class="helper-link">
                <a href="https://vestige.fi/asset/2217970766/" class="outlink" target="_blank" rel="noreferrer"><h5>Vestige</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://explorer.perawallet.app/asset/2217970766/" class="outlink" target="_blank" rel="noreferrer"><h5>Pera Explorer</h5></a>
              </div>
            </div>
        </section>
        <section>
          <div class="link_heading">
            <h3>Community</h3>
            <p>Tinuman is more than a memecoin; it’s a community-driven movement that blends fun, creativity, and blockchain innovation. Embrace the meme and be part of a community that’s changing the game on Algorand!</p>
          </div>
          <div>
              <div class="helper-link">
                <a href="https://twitter.com/tinualgo" class="outlink" target="_blank" rel="noreferrer"><h5>Twitter</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://t.me/tinualgo" class="outlink" target="_blank" rel="noreferrer"><h5>Telegram</h5></a>
              </div>
          </div>
        </section>
      </div>
      <footer>
      <p class="disclaimer">Disclaimer</p>
        <p class="disclaimer">We never promise anything, this coin was created just for fun. We are never responsible for losses caused by significant price spikes. This coin was created just as a joke. Buying means agreeing to all the consequences that will occur.</p>
        
        <div class="end">
          <p class="copyright">© 2024 Tinuman</p>
        </div>
      </footer>
    </div>
  )
}